import React, { Fragment } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import SEO from "./SEO"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Container from "./Container"
import Notification from "./Notification"

import styles from "./utils/layout.module.scss"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  path,
  title,
  children,
  subtitle,
  seoTitle,
  progress,
  isPrivate,
  background,
  pageContext,
  display = { footer: true, helpCenterBanner: false },
}) => {
  let pathArray = path ? path.split("/") : []

  return (
    <Fragment>
      <div className={classNames(`is-background-${background}`)}>
        <SEO title={seoTitle} />
        <Navbar path={pathArray} />
        <Notification pageContext={pageContext} />
        {!!progress && (
          <progress
            className={classNames(
              "progress is-small is-info",
              styles["progressBar"]
            )}
            value={`${progress}`}
            max="100"
          />
        )}

        {title && (
          <Container isCentered fullhd={6}>
            <h2
              className={classNames(
                "has-text-centered mb-0 has-text-primary mx-2-mobile is-size-3-mobile",
                {
                  "pb-0": !subtitle,
                  "pb-2": !!subtitle,
                }
              )}
            >
              {title}
              <br />
              <span className="subtitle has-text-grey has-text-centered my-1 is-size-4 mx-1-mobile is-size-5-mobile">
                {subtitle}
              </span>
            </h2>
          </Container>
        )}

        <main className="pb-3">{children}</main>
        {display.footer && <Footer />}
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
