import firebase from "firebase"
import { camelizeKeys } from "humps"
import { isBrowser } from "services/general"
import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER =
      Object.keys(window.sessionStorage).filter((item) =>
        item.startsWith("firebase:authUser")
      )[0] || "medstaffData" in window.sessionStorage

    return USER
  }
}

export const getSignedInUser = () => {
  if (isBrowser()) {
    let fauthUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    let authUser = JSON.parse(sessionStorage.getItem(fauthUser))
    // let authUser = JSON.parse(sessionStorage.getItem(authUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))
    const medstaffData = JSON.parse(sessionStorage.getItem("medstaffData"))

    return { authUser, userData, addresses, medstaffData }
  } else return {}
}

export const getUserEnrollment = async ({ userData }) => {
  if (isBrowser()) {
    let enrollments = []
    let enrollmentsQuery = await firebase
      .firestore()
      .collection(`users/${userData?.id}/enrollments`)
      .get()

    enrollmentsQuery.forEach((data) => {
      enrollments.push({ ...data?.data(), id: data?.id })
    })

    let enrollment = enrollments?.find(
      (data) => data?.subdomain === GATSBY_WEBSITE_URL
    )

    return enrollment
  }
  return {}
}

export const hasRoles = () => {
  const { userData, medstaffData } = getSignedInUser()

  let hasRoles = false

  if (userData) {
    let activeRole = userData?.roles?.find(
      (role) => role?.subdomain === GATSBY_WEBSITE_URL
    )
    if (activeRole) hasRoles = true
  }
  if (medstaffData) return true

  return hasRoles
}
